import React from 'react'
import loadable from '@loadable/component'

const BubbleContainer = loadable(() => import('./layout'))

const BubbleChat = ({ children }) => {
    return <>
        {children}
        {typeof window !== 'undefined' ? <BubbleContainer/> : <></>}        
    </>
}

export default BubbleChat