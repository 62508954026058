require('./src/assets/css/layout.css')
require('./src/assets/css/typography.css')

const React = require("react")
const GlobalState = require("./src/components/globalstates").default
const BottomNavigation = require('./src/components/Homemade/BottomNavigator').default
const BubbleChat = require('./src/components/BubbleChat').default

let paths = [
  '/account', 
  '/', 
  '/search', 
  '/alerts'
]
// 4 // Logs when the client route changes
// exports.onRouteUpdate = ({ location, prevLocation }) => {
//
//
// }
// Wraps every page in a component
// exports.wrapRootElement = ({ element }) => {
//   return <ErrorBoundary fallback={Fallback}>{element}</ErrorBoundary>
// }


exports.wrapRootElement = ({ element }) => {
  return <GlobalState>{element}</GlobalState>
}

exports.wrapPageElement = ({element}) => {
  return <BottomNavigation> <BubbleChat>{element}</BubbleChat> </BottomNavigation>
}

exports.onRouteUpdate = (data) => {
  //remove navigation bar

  if(!paths.includes(data.location.pathname)){
    let navigationBar = document.getElementsByClassName('MuiBottomNavigation-root')[0]
    console.log('does not include')
    console.log(navigationBar)
    navigationBar.style.display = "none"  
  }else {
    let navigationBar = document.getElementsByClassName('MuiBottomNavigation-root')[0]
    navigationBar.style.display = "flex"
  }
}

exports.onServiceWorkerUpdateReady = () => {
  if (
    window.confirm(
      "Sparkle has been updated. Do you wish to reload the app to get the new data?"
    )
  ) {
    window.location.reload(true)
  }
}
// => window.location.reload(true);


exports.onPrefetchPathname = (data) => {
  console.log("prefetch pat namek")
}
