import { API } from "./api-config"


import ls from '../services/securels'

export const formData = (_data) => {
  let new_formData = new FormData()
  for (var key in _data) {
    new_formData.append(key, _data[key])
  }
  return new_formData
}

const post = async (_data, _url) => {
  const token = ls.get("token")

  return await fetch(`${API}/${_url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(_data),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Unable to process request")
      }
      return response.json()
    })
    .catch((err) => {
      return err
    })
}

const get = async (_url) => {
  return await fetch(`${API}/${_url}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {})
}

const patch = async (_data, _url) => {
  return await fetch(`${API}/${_url}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(_data),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {})
}

const remove = async (_data, _url) => {
  return await fetch(`${API}/${_url}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {})
}

const getUser = async (userId) => {
  const token = ls.get("token")

  return await fetch(`${API}/user/${userId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {})
}

const getListPurchaseHistory = (_url, token) => {
  return fetch(`${API}${_url}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {})
}

const update = (userId, token, user) => {
  return fetch(`${API}/user/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {})
}

const updatePhoto = async (userId, token, image) => {
  const formdata = new FormData()

  formdata.append("file", image)

  return await fetch(`${API}/user/photo/${userId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {})
}

//Get User Balance Amount
const getUserBalance = async (id, token) => {
  return await fetch(`${API}/wallet/top-up/user-balance/${id}` , {
      method: "GET",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
      }
  })
  .then(response => {
      return response.json()
  })
  .catch(err => console.log(err));
}

//Get List of Transaction
const getTransactionHistory = async (id, token) => {
  return await fetch(`${API}/wallet/transaction-history/${id}` , {
      method: "GET",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
      }
  })
  .then(response => {
      return response.json()
  })
  .catch(err => console.log(err));
}

//Send Submit Proof Data Only
const submitProofData = (id,data, token) =>{
  return fetch(`${API}/wallet/submit-proof/${id}`, {
      method: "POST",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
  })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
}

//Send Image Submit Proof
const submitProofImage = (id, data) =>{
  const formData = new FormData();
  formData.append('file', data);

  return fetch(`${API}/wallet/submit-proof/image/${id}`, {
      method: "PUT",
      body: formData
  })
  .then(response => {
      return response.json();
  })
  .catch(err => console.log(err));
}

//Store User Balance Amount
const storeUserBalance = ( id, data, token) => {
  return fetch(`${API}/wallet/top-up/user-balance/${id}` , {
      method: "POST",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
  })
  .then(response => {
      return response.json();
  })
  .catch(err => console.log(err));
}
const storeTransaction = (userId, data, token) =>{
  return fetch(`${API}/wallet/transaction-history/${userId}` , {
      method: "POST",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}

const deductUserBalance = (userId, data, token) =>{
  return fetch(`${API}/wallet/deduction/user-balance/${userId}` , {
    method: "PUT",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(data)
  })
  .then(response => {
      return response.json();
  })
  .catch(err => console.log(err));
}

const postTopUpDetails = (userId, data, token) =>{
  return fetch(`${API}/wallet/top-up/details/${userId}`,{
    method:"POST",
    headers:{
      Accept:"application/json",
      "Content-Type":"application/json",
      Authorization: `Bearer ${token}`
  },
    body: JSON.stringify(data)
  })
  .then(response =>{
    return response.json();
  })
  .catch(err => console.log(err))
}

const getTopUpDetails = (userId, token) =>{
  return fetch(`${API}/wallet/top-up/details/${userId}`,{
    method:"GET",
    headers:{
      Accept:"application/json",
      "Content-Type":"application/json",
      Authorization: `Bearer ${token}`
    },
  })
  .then(response =>{
    return response.json();
  })
  .catch(err => console.log(err))
}

export {
  post,
  get,
  patch,
  remove,
  getUser,
  getListPurchaseHistory,
  getTransactionHistory,
  submitProofData,
  submitProofImage,
  storeUserBalance,
  storeTransaction,
  update,
  updatePhoto,
  getUserBalance,
  deductUserBalance,
  postTopUpDetails,
  getTopUpDetails
}
