import getUrlVars from "../../helpers/getUrlVars"
import ls from '../securels'

export const getUser = () => {

  const user = {
    token: ls.get("token"),
    userId: ls.get("userId"),
    email: ls.get("email"),
    name: ls.get("name"),
    phone: ls.get("phone"),
    messenger: ls.get("messenger"),
  }

  return user
}

export const isLoggedIn = () => {
  const user = getUser()

  return user.userId.length > 0 ? true : false
}

export const getUserFromReactNative =  () => {
  if(getUrlVars('id') && getUrlVars('token') && getUrlVars('email') && getUrlVars('name')
    && getUrlVars('phone') && getUrlVars('useraddress') ){

    ls.set('userId', getUrlVars('id'))
    ls.set('token', getUrlVars('token'))
    ls.set('email', getUrlVars('email'))
    ls.set('name', getUrlVars('name'))
    ls.set('phone', getUrlVars('phone'))
    ls.set('address', getUrlVars('useraddress'))
    ls.set('photo', getUrlVars('photo'))
    ls.set('messenger', getUrlVars('messenger'))
  }
}
