// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-air-js": () => import("./../../../src/pages/air.js" /* webpackChunkName: "component---src-pages-air-js" */),
  "component---src-pages-alerts-js": () => import("./../../../src/pages/alerts.js" /* webpackChunkName: "component---src-pages-alerts-js" */),
  "component---src-pages-bag-js": () => import("./../../../src/pages/bag.js" /* webpackChunkName: "component---src-pages-bag-js" */),
  "component---src-pages-chatsupport-js": () => import("./../../../src/pages/chatsupport.js" /* webpackChunkName: "component---src-pages-chatsupport-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-express-js": () => import("./../../../src/pages/express.js" /* webpackChunkName: "component---src-pages-express-js" */),
  "component---src-pages-food-js": () => import("./../../../src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-helpcenter-js": () => import("./../../../src/pages/helpcenter.js" /* webpackChunkName: "component---src-pages-helpcenter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-order-[id]-js": () => import("./../../../src/pages/order/[id].js" /* webpackChunkName: "component---src-pages-order-[id]-js" */),
  "component---src-pages-orderdetails-track-order-id-js": () => import("./../../../src/pages/orderdetails/track/[orderId].js" /* webpackChunkName: "component---src-pages-orderdetails-track-order-id-js" */),
  "component---src-pages-paymongo-refunddetails-ref-id-js": () => import("./../../../src/pages/paymongo/refunddetails/[refId].js" /* webpackChunkName: "component---src-pages-paymongo-refunddetails-ref-id-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-ratings-js": () => import("./../../../src/pages/ratings.js" /* webpackChunkName: "component---src-pages-ratings-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-reset-js": () => import("./../../../src/pages/reset.js" /* webpackChunkName: "component---src-pages-reset-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shop-shop-id-js": () => import("./../../../src/pages/shop/[shopId].js" /* webpackChunkName: "component---src-pages-shop-shop-id-js" */),
  "component---src-pages-submitratings-js": () => import("./../../../src/pages/submitratings.js" /* webpackChunkName: "component---src-pages-submitratings-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-user-history-[id]-js": () => import("./../../../src/pages/user/history/[id].js" /* webpackChunkName: "component---src-pages-user-history-[id]-js" */),
  "component---src-pages-user-history-index-js": () => import("./../../../src/pages/user/history/index.js" /* webpackChunkName: "component---src-pages-user-history-index-js" */),
  "component---src-pages-user-index-js": () => import("./../../../src/pages/user/index.js" /* webpackChunkName: "component---src-pages-user-index-js" */),
  "component---src-pages-user-topup-js": () => import("./../../../src/pages/user/topup.js" /* webpackChunkName: "component---src-pages-user-topup-js" */),
  "component---src-pages-user-topupcheckout-js": () => import("./../../../src/pages/user/topupcheckout.js" /* webpackChunkName: "component---src-pages-user-topupcheckout-js" */),
  "component---src-pages-user-update-js": () => import("./../../../src/pages/user/update.js" /* webpackChunkName: "component---src-pages-user-update-js" */),
  "component---src-pages-user-wallet-js": () => import("./../../../src/pages/user/wallet.js" /* webpackChunkName: "component---src-pages-user-wallet-js" */),
  "component---src-pages-vortex-billspayment-js": () => import("./../../../src/pages/vortex/billspayment.js" /* webpackChunkName: "component---src-pages-vortex-billspayment-js" */),
  "component---src-pages-vortex-topup-js": () => import("./../../../src/pages/vortex/topup.js" /* webpackChunkName: "component---src-pages-vortex-topup-js" */),
  "component---src-pages-vortex-transactions-[type]-ref-id-js": () => import("./../../../src/pages/vortex/transactions/[type]/[refId].js" /* webpackChunkName: "component---src-pages-vortex-transactions-[type]-ref-id-js" */),
  "component---src-pages-vortex-transactions-index-js": () => import("./../../../src/pages/vortex/transactions/index.js" /* webpackChunkName: "component---src-pages-vortex-transactions-index-js" */),
  "component---src-pages-vortex-voucher-js": () => import("./../../../src/pages/vortex/voucher.js" /* webpackChunkName: "component---src-pages-vortex-voucher-js" */)
}

