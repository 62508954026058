import getUrlVars from "../../helpers/getUrlVars"

export function getAddressFromReactNative() {

	if (getUrlVars('address') && getUrlVars('lat') && getUrlVars('lng')) {
		return {
			address: getUrlVars('address'),
			lat: getUrlVars('lat'),
			lng: getUrlVars('lng')
		}
	}

	return null
}