
import { API } from "../../api-config"
import ls from '../../../services/securels'

export const getAllBroadcasts = async () => {


  let userId = ls.get('userId')
  let token = ls.get('token')

  let url = userId ? `${API}/broadcasts/all/types/${userId}` : `${API}/broadcasts`

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  })
    .then((response) => {
      return response
    })
    .catch((err) => {

      return err
    })
}


export const markAsRead = async (broadcastId) => {

  let userId = ls.get('userId')
  let token = ls.get('token')



  return await fetch(`${API}/broadcasts/${broadcastId}/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  })
    .then((response) => {
      return response
    })
    .catch((err) => {

      return err
    })
}



export const getNotifications = async () => {


  let userId = ls.get('userId')
  let token = ls.get('token')

  let url = userId ? `${API}/broadcasts/notifications/${userId}` : `${API}/broadcasts`

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  })
    .then((response) => {
      return response
    })
    .catch((err) => {

      return err
    })
}



export const getPromotions = async () => {


  let userId = ls.get('userId')
  let token = ls.get('token')

  let url = userId ? `${API}/broadcasts/promotions/${userId}` : `${API}/broadcasts`

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  })
    .then((response) => {
      return response
    })
    .catch((err) => {

      return err
    })
}



export const getAdvisory = async () => {


  let userId = ls.get('userId')
  let token = ls.get('token')

  let url = userId ? `${API}/broadcasts/advisory/${userId}` : `${API}/broadcasts`

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  })
    .then((response) => {
      return response
    })
    .catch((err) => {

      return err
    })
}


export const createSelectedBroadcast = async ({ title, body }) => {


  let userId = ls.get('userId')
  let token = ls.get('token')

  const formdata = new FormData();

  formdata.append("title", title);
  formdata.append("body", body);
  formdata.append("createdBy", userId);
  formdata.append("type", "Notification");
  formdata.append("link", "sparkles.com.ph");
  formdata.append("published", true);
  formdata.append("targetUsers", userId);

  return await fetch(
    `${API}/broadcasts/selected/post`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: formdata
    })
    .then((response) => {
      return response
    })
    .catch((err) => {

      return err
    })
}


export const createOrderUpdateBroadcast = async ({title, body, metaData}) => {
  try {

    let userId = ls.get('userId')
    let token = ls.get('token')
  
    const formdata = new FormData();
  
    formdata.append("title", title);
    formdata.append("body", body);
    formdata.append("createdBy", userId);
    formdata.append("type", "OrderUpdate");
    formdata.append("link", "sparkles.com.ph");
    formdata.append("published", true);
    formdata.append("metaData", JSON.stringify(metaData));
    formdata.append("targetUsers", userId);
  
    return await fetch(
      `${API}/broadcasts/selected/post`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formdata
      })
      .then((response) => {

        if(response?.status !== 200){
          throw Error("Something went wrong")
        }


        return response.json()
      })
   
  } catch (error) {
    throw error
  }
}