import { API } from '../../api-config'

export const reverseGeocode = async (long, lat) => {
  try {
    let response = await fetch(`${API}/user/location?lat=${lat}&long=${long}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })

    let jsonData = await response.json()


    console.log(jsonData)
    return jsonData;
  } catch (err) {
    alert('there has been an error')
    console.log(err)
  }


  
}
